/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  FormLabel,
  useToast,
  Radio, 
  RadioGroup
} from '@chakra-ui/react';
// Custom components

import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import React, { useState,useEffect } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { API_BASE_URL } from 'constants/apiConstants';
import { ASSETS_URL } from 'constants/apiConstants';
import { useParams,useNavigate } from 'react-router-dom';

export default function EditBusiness() {
  const navigate = useNavigate();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({
    user: true,
    address: false,
  });
  const [input,setInput] = useState([]);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const [businessId, setBusinessId] = useState('');
  const [businessImage, setBusinessImage] = useState('');
  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.title) {
      errors.title = 'Title field is required';
    }
    return errors;
  };

  const handleSubmitBusinessInfo = (e) => {
    e.preventDefault();
    const newErrors = validateForm(input);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        "title":input.title,
        "businessId": businessId
      }
      axios.post(API_BASE_URL+'business/store',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === true){
          setBusinessId(response.data.data.businessId)
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
          addressTab.current.click();
        }else if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
      .catch(function (error){
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  };

  const handleBusinessImageUpload = (acceptedFiles) => {
    if(businessId === ''){
      toast({ title: 'Please fill business info details first.',status: 'error',isClosable: true,position: 'top'})
      return false;
    }
    const formData = new FormData();
    formData.append("businessImage",acceptedFiles[0]);
    formData.append("business_id",businessId);
    axios.post(API_BASE_URL+'business/upload/image',formData,{
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      }
    })
    .then(function (response){
      if(response.data.apistatus === true){
        setBusinessImage(response.data.data)
        toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
      }else if(response.data.apistatus === false){
        toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
      }
    })
    .catch((error) => {
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  const removeImage = (image) => {
    const payload = {
        'business_id': businessId,
        'image': image
    }
    axios.post(API_BASE_URL+'delete/business-image',payload,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
    })
    .then(function (response){
        setBusinessImage(null)
    })
    .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  };

  const getBusiness = (e) => {
      axios.get(API_BASE_URL+'get/business/'+id,{
          headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
      })
      .then(function (response){
          setBusinessId(response.data.data.id);
          setInput({
            title: response.data.data.title,
          });
          setBusinessImage(response.data.data.image);
      }) 
      .catch((error) => {
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
  }

  const redirectToListing = (e) => {
      navigate("/admin/business/overview");
  }

  useEffect(() => {
      getBusiness();
  },[])

  const userTab = React.useRef();
  const addressTab = React.useRef();

  return (
    <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: '125px', lg: '75px' }}
        position="relative"
      >
        <Box
          h="45vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        />
  
        <Tabs
          variant="unstyled"
          zIndex="0"
          mt={{ base: '60px', md: '165px' }}
          display="flex"
          flexDirection="column"
        >
          <TabList
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
          >
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={userTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  address: false,
                  profile: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.address ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '30px' },
                  top: {
                    sm: activeBullets.user ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  bottom: activeBullets.user ? '40px' : '38px',
  
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.user ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.user ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.user ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Business Info
                </Text>
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={addressTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  address: true,
                  profile: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.profile ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '32px' },
                  top: '6px',
                  position: 'absolute',
                  bottom: activeBullets.address ? '40px' : '38px',
  
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.address ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.address ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.address ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Image
                </Text>
              </Flex>
            </Tab>
          </TabList>
          <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Business Info
                </Text>
                <form onSubmit={handleSubmitBusinessInfo}>
                  <Flex direction="column" w="100%">
                      <Stack direction="column" spacing="20px">
                        <InputField
                            mb="0px"
                            id="title"
                            placeholder="eg. Esthera"
                            label="Title"
                            name="title"
                            onChange={handleChange}
                            value={input.title}
                        />
                        {errors.title &&
                          <span className="error-message">
                              {errors.title}
                          </span>
                        }
                      </Stack>
                      <Flex justify="space-between" mt="24px">
                        <Button
                          variant="darkBrand"
                          fontSize="sm"
                          borderRadius="16px"
                          w={{ base: '128px', md: '148px' }}
                          h="46px"
                          ms="auto"
                          type='submit'
                          // onClick={() => addressTab.current.click()}
                        >
                          Next
                        </Button>
                      </Flex>
                  </Flex>
                </form>
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                  <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                      Media
                  </Text>
                  <Flex direction="column" w="100%">
                      <FormLabel
                      ms="10px"
                      htmlFor="property_logo"
                      fontSize="sm"
                      color=''
                      fontWeight="bold"
                      _hover={{ cursor: 'pointer' }}
                      >
                      Profile Image
                      </FormLabel>

                      <Dropzone onDrop={handleBusinessImageUpload} accept="image/*" maxFiles={1} minH={200}>
                      {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                          const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";

                          return (
                          <div
                          {...getRootProps({
                              className: `dropzone ${additionalClass}`
                          })}
                          >
                              <input {...getInputProps()} />
                              <p>Drag'n'drop images,or click to select files</p>
                          </div>
                          );
                      }}
                      </Dropzone>
                      {businessImage && (
                          <div className='new_box'>
                              <img src={ASSETS_URL+'business/'+businessImage} className='img-container logo-image' alt='upload profile file' width="50%"/>
                              <button className='remove-image' onClick={() => removeImage(businessImage)}>X</button>
                          </div>
                      )}
                  </Flex>
                  <Flex justify="space-between" mt="24px">
                      <Button
                          variant="darkBrand"
                          fontSize="sm"
                          borderRadius="16px"
                          w={{ base: '128px', md: '148px' }}
                          h="46px"
                          ms="auto"
                          type='submit'
                          onClick={() => redirectToListing()}
                      >
                      Save
                      </Button>
                  </Flex>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
  );
}
