/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    Select,
    FormLabel,
    useToast,
  } from '@chakra-ui/react';
  // Custom components
  import Card from 'components/card/Card';
  import InputField from 'components/fields/InputField';
  import React, { useState,useEffect } from 'react';
  import axios from 'axios';
  import { API_BASE_URL } from 'constants/apiConstants';
  import { useNavigate,useParams } from 'react-router-dom';

  export default function EditStandoutAmenities() {
    const navigate = useNavigate();
    const { id } = useParams();
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
      user: true,
      address: false,
      profile: false,
    });
    const [input,setInput] = useState([])
    const [errors, setErrors] = useState({});
    const [satandoutAmenityId, setSatandoutAmenityId] = useState('');
    const toast = useToast()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInput({
            ...input,
            [name]: value,
        });
    };

    const validateForm = (data) => {
        const errors = {};
        
        if (!data.title) {
          errors.title = 'Title field is required';
        }

        if (data.status === '') {
          errors.status = 'Status field is required';
        }
        return errors;
    };

    const handleSubmitAmenitiesTypeInfo = (e) => {
        e.preventDefault();
        const newErrors = validateForm(input);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const payload = {
              "amenityTypeId":satandoutAmenityId,
              "title":input.title,
              "status":input.status,
            }
            axios.post(API_BASE_URL+'standout-amenities/store-update',payload,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
                }
            })
            .then(function (response){
                if(response.data.apistatus === true){
                    toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
                    navigate("/admin/standout-amenities/overview");
                }else if(response.data.apistatus === false){
                    toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
                }
            })
            .catch(function (error){
                toast({ title: error,status: 'error',isClosable: true,position: 'top'})
            })
        }
    };

    const getStandoutAmenity = (e) => {
        axios.get(API_BASE_URL+'get/standout-amenity/'+id,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
            }
        })
        .then(function (response){
            setSatandoutAmenityId(response.data.data.standoutAmenity.id);
            setInput({
                title: response.data.data.standoutAmenity.title,
                status: response.data.data.standoutAmenity.status,
            });
        }) 
        .catch((error) => {
            toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
    }

    useEffect(() => {
        getStandoutAmenity();
    },[])

    const userTab = React.useRef();
  
    return (
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: '125px', lg: '75px' }}
        position="relative"
      >
        <Box
          h="45vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        />
  
        <Tabs
          variant="unstyled"
          zIndex="0"
          mt={{ base: '60px', md: '165px' }}
          display="flex"
          flexDirection="column"
        >
          <TabList
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
          >
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={userTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  address: false,
                  profile: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.address ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '30px' },
                  top: {
                    sm: activeBullets.user ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  bottom: activeBullets.user ? '40px' : '38px',
  
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.user ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.user ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.user ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Standout Amenities Info
                </Text>
              </Flex>
            </Tab>
          </TabList>

          <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                    Standout Amenities Info
                </Text>
                <form onSubmit={handleSubmitAmenitiesTypeInfo}>
                    <Flex direction="column" w="100%">

                        <Stack direction="column" spacing="20px">
                            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                                <Flex direction="column">
                                    <InputField
                                        mb="0px"
                                        id="title"
                                        placeholder="eg. PARKING"
                                        label="Title"
                                        onChange={handleChange}
                                        value={input.title}
                                        name="title"
                                    />
                                    {errors.title &&
                                        <span className="error-message">
                                            {errors.title}
                                        </span>
                                    }
                                </Flex>

                                <Flex direction="column">
                                    <FormLabel
                                    ms="10px"
                                    htmlFor="developer"
                                    fontSize="sm"
                                    color=''
                                    fontWeight="bold"
                                    _hover={{ cursor: 'pointer' }}
                                    >
                                        Status
                                    </FormLabel>

                                    <Select
                                    id="status"
                                    value={input.status}
                                    name="status"
                                    onChange={handleChange}
                                    borderRadius="16px"
                                    >
                                        <option value=''>Select...</option>
                                        <option value="1">Active</option >
                                        <option value="0">Inactive</option >
                                    </Select>
                                    {errors.status &&
                                    <span className="error-message">
                                        {errors.status}
                                    </span>
                                    }
                                </Flex>
                            </SimpleGrid>
                        </Stack>

                        <Flex justify="space-between" mt="24px">
                            <Button
                            variant="darkBrand"
                            fontSize="sm"
                            borderRadius="16px"
                            w={{ base: '128px', md: '148px' }}
                            h="46px"
                            ms="auto"
                            type='submit'
                            >
                            Submit
                            </Button>
                        </Flex>
                    </Flex>
                </form>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    );
  }
  