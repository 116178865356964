import { Flex,useToast } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useEffect, useState } from "react";
import SearchTableUsers from "views/admin/properties/overview/components/SearchTablePropertiesOverivew";
import { columnsDataPropertiesOverview } from "views/admin/properties/overview/variables/columnsDataPropertiesOverview";
import axios from "axios";
import { API_BASE_URL } from "constants/apiConstants";

export default function PropertiesOverview() {
  const [properties,setProperties] = useState([]);
  const toast = useToast()
  const fetchProperties = () => {
    axios.get(API_BASE_URL+'get/properties',{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      }
    })
    .then((response) => {
      setProperties(response.data.data)
    })
    .catch((error) => {
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  useEffect(() => {
    fetchProperties();
  },[]);

  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='0px'>
        <SearchTableUsers
          tableData={properties}
          columnsData={columnsDataPropertiesOverview}
        />
      </Card>
    </Flex>
  );
}