import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { 
  Flex,
  useToast,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay, 
  Button,
  Badge,
  Text,
  useColorModeValue,
  Avatar,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { API_BASE_URL } from "constants/apiConstants";
import axios from 'axios';
import { Link } from "react-router-dom";
import { MdDelete,MdEdit,MdBlock,MdDownload } from "react-icons/md";
import { ASSETS_URL } from "constants/apiConstants";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';

export default function UsersOverview() {
  const [agents,setAgents] = useState([]);
    const toast = useToast()
    const [confirmBoxMessage, setConfirmBoxMessage] = useState();
    const [confirmBoxTitle, setConfirmBoxTitle] = useState();
    const [isOpen, setIsOpen] = React.useState();
    const [agentId, setAgentId] = React.useState()
    const cancelRef = React.useRef();
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      'status': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
      'USER NAME': { value: null, matchMode: FilterMatchMode.IN },
      'EMAIL': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      'Account Type': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      'Created At': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const fetchAgents = () => {
      axios.get(API_BASE_URL+'get/agents',{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        setAgents(response.data.data)
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }

    const columns = [
        { field: 'agent_id', header: 'actions' },
        { field: 'id', header: 'id' },
        { field: 'profile_picture', header: 'profile image' },
        { field: 'name', header: 'name' },
        { field: 'user_name', header: 'user name' },
        { field: 'email', header: 'email' },
        { field: 'account_type', header: 'account type' },
        { field: 'date', header: 'created at' },
    ];

    const handleRowOrderChange = async (params) => {
      const payload ={
        'dropIndex' : params.dropIndex,
        'value' : params.value
      }

      axios.post(API_BASE_URL+'update/agent/row-priority',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        setAgents(params.value)
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    };

    const onClose = () => setIsOpen(false)
  
    const openDeleteAgentConfirmBox = (id) => {
      setConfirmBoxTitle("Delete Agent");
      setConfirmBoxMessage("Are you sure you want your delete this listing? This listing will be deleted permanently. This action can’t be undone.");
      setIsOpen(true);
      setAgentId(id);
    }

    const actionBodyTemplate = (rowData) => {
      return (
        <Flex cursor='pointer' mx="auto">
          <Link to={`/admin/agents/edit-agent/${rowData.agent_id}`}>
            <Icon color='secondaryGray.500' as={MdEdit} w='20px' h='20px' />
          </Link>

          <Link onClick={e => openDeleteAgentConfirmBox(rowData.agent_id)}>
            <Icon color='secondaryGray.500' as={MdDelete} w='20px' h='20px' />
          </Link>
        </Flex>
      );
    };

    const imageBodyTemplate = (rowData) => {
      return (
        <Flex align='center'>
          <Avatar
            src={ASSETS_URL+"agent/profile/"+rowData.profile_picture} 
            h='60px'
            w='60px'
            me='10px'
          />
        </Flex>
      );
    };

    const eventHandle = () => {
      setIsOpen(false);
      const payload = {
          'id':agentId
      };
      axios.post(API_BASE_URL+'delete/agent',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
        window.location.reload();
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
  
    const renderHeader = () => {
      return (
          <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
              <div className='d-flex'>
                <div className='search-box dev-search-box'>
                  <IconField iconPosition="left">
                      <InputIcon className="pi pi-search" />
                      <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                  </IconField>
                </div>
              </div>
          </div>
      );
    };
  
    const header = renderHeader();

    useEffect(() => {
        fetchAgents();
    }, []);

    const dynamicColumns = columns.map((col, i) => {
      if(col.header === 'actions'){
        return <Column header={col.header} body={actionBodyTemplate} exportable={false}></Column>;
      }if(col.header === 'profile image'){
        return <Column header={col.header} body={imageBodyTemplate} exportable={false}></Column>;
      }else{
        return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} />;
      }
    });

    return (
      <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {confirmBoxTitle}
            </AlertDialogHeader>

            <AlertDialogBody>
              {confirmBoxMessage}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={eventHandle} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
        <Card px='0px'>
            <div className="card">
                <DataTable value={agents} header={header} reorderableColumns reorderableRows onRowReorder={handleRowOrderChange} filters={filters} globalFilterFields={['id', 'name', 'user_name', 'email', 'account_type', 'date']}
                    emptyMessage="No agent found.">
                    <Column rowReorder style={{ width: '3rem' }} />
                    {dynamicColumns}
                </DataTable>
            </div>
          </Card>
      </Flex>
      </>
    );
}
        