import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  Flex,
  useToast, 
  Button,
  Icon,
  Avatar,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} 
from "@chakra-ui/react";
import Card from "components/card/Card";
import { API_BASE_URL } from "constants/apiConstants";
import axios from 'axios';
import { Link } from "react-router-dom";
import { MdDelete,MdEdit } from "react-icons/md";
import { ASSETS_URL } from "constants/apiConstants";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import 'primeicons/primeicons.css';

export default function ReorderDemo() {
  const [properties,setProperties] = useState([]);
  const [confirmBoxTitle, setConfirmBoxTitle] = useState();
  const [confirmBoxMessage, setConfirmBoxMessage] = useState();
  const [isOpen, setIsOpen] = React.useState();
  const cancelRef = React.useRef()
  const [developerId, setDeveloperId] = React.useState();
  const toast = useToast();
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    'Profile Image': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    'USER NAME': { value: null, matchMode: FilterMatchMode.IN },
    'EMAIL': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    'Account Type': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    'Created At': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
  });

  const fetchProperties = () => {
    axios.get(API_BASE_URL+'get/developers',{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      }
    })
    .then((response) => {
      setProperties(response.data.data)
    })
    .catch((error) => {
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  const columns = [
      { field: 'developer_id', header: 'actions' },
      { field: 'id', header: 'ID' },
      { field: 'profile_picture', header: 'Profile Image' },
      { field: 'name', header: 'USER NAME' },
      { field: 'email', header: 'EMAIL' },
      { field: 'account_type', header: 'Account Type' },
      { field: 'date', header: 'Created At' },
  ];

  const handleRowOrderChange = async (params) => {
    const payload ={
      'dropIndex' : params.dropIndex,
      'value' : params.value
    }

    axios.post(API_BASE_URL+'update/developer/row-priority',payload,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      }
    })
    .then((response) => {
      setProperties(params.value)
    })
    .catch((error) => {
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  };

  const openDeleteDeveloperConfirmBox = (id) => {
    setConfirmBoxTitle("Delete Developer");
    setConfirmBoxMessage("Are you sure you want your delete this listing? This listing will be deleted permanently. This action can’t be undone.");
    setIsOpen(true);
    setDeveloperId(id);
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const eventHandle = () => {
    setIsOpen(false);
    const payload = {
        'id':developerId
    };
    axios.post(API_BASE_URL+'delete/developer',payload,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      }
    })
    .then((response) => {
      toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
      window.location.reload();
    })
    .catch((error) => {
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <Flex cursor='pointer' mx="auto">
        <Link to={`/admin/developers/edit-developer/${rowData.developer_id}`}>
          <Icon color='secondaryGray.500' as={MdEdit} w='20px' h='20px' />
        </Link>
        <Link onClick={e => openDeleteDeveloperConfirmBox(rowData.developer_id)}>
            <Icon color='secondaryGray.500' as={MdDelete} w='20px' h='20px' />
        </Link>
      </Flex>
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <Flex align='center'>
        <Avatar
          src={ASSETS_URL+"developer/profile/"+rowData.profile_picture} 
          h='60px'
          w='60px'
          me='10px'
        />
      </Flex>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
};

  const renderHeader = () => {
    return (
        <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
            <div className='d-flex'>
              <div className='search-box dev-search-box'>
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </IconField>
              </div>
            </div>
        </div>
    );
  };

  const header = renderHeader();

  const dynamicColumns = columns.map((col, i) => {
    if(col.header === 'actions'){
      return <Column header={col.header} body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>;
    }if(col.header === 'Profile Image'){
      return <Column header={col.header} body={imageBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>;
    }else{
      return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} />;
    }
  });

  useEffect(() => {
    fetchProperties();
  }, []);

  return (
    <>
    <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {confirmBoxTitle}
            </AlertDialogHeader>

            <AlertDialogBody>
              {confirmBoxMessage}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={eventHandle} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
    </AlertDialog>

    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='0px'>
          <div className="card">
          <DataTable value={properties} header={header}
                    reorderableColumns reorderableRows onRowReorder={handleRowOrderChange}
                    filters={filters} globalFilterFields={['id', 'name', 'email', 'account_type', 'date']}
                    emptyMessage="No developer found.">
              {/* <DataTable value={properties} header={header} reorderableColumns reorderableRows onRowReorder={handleRowOrderChange} tableStyle={{ minWidth: '50rem' }}> */}
                  <Column rowReorder style={{ width: '3rem' }} />
                  {dynamicColumns}
              </DataTable>
          </div>
        </Card>
  </Flex>
  </>
  );
}
        