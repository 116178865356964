/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    FormLabel,
    Select,
    useToast,
    Link
  } from '@chakra-ui/react';
  // Custom components
  import Card from 'components/card/Card';
  import InputField from 'components/fields/InputField';
  import TextField from 'components/fields/TextField';
  import React, { useState,useEffect } from 'react';
  import axios from 'axios';
  import { API_BASE_URL,ASSETS_URL } from 'constants/apiConstants';
  import Dropzone from 'react-dropzone';
  import { useParams } from 'react-router-dom';
  import { useNavigate } from 'react-router-dom';
  import SortableList, { SortableItem } from 'react-easy-sort'; 
  import arrayMove from "array-move";
  import { makeStyles } from '@mui/styles';
  import Multiselect from 'multiselect-react-dropdown';
  import { IoMdClose } from "react-icons/io";

  const useStyles = makeStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      userSelect: "none"
    },
    item: {
      position: "relative",
      flexShrink: 0,
      display: "flex",
      margin: 8,
      cursor: "grab",
      userSelect: "none",
      boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2)", 
    },
    image: {
      width: 150,
      height: 150,
      pointerEvents: "none",
      borderRadius: "8px",
    },
    button: {
      position: "absolute",
      bottom: 0,
      right: 0
    },
    dragged: {
      boxShadow:
        "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
      "& button": {
        opacity: 0
      }
    }
  });
  
  export default function EditProperty() {
    const navigate = useNavigate();
    const propertyTab = React.useRef();
    const locationTab = React.useRef();
    const mediaTab = React.useRef();
    const { id } = useParams();
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const [activeBullets, setActiveBullets] = useState({
      user: true,
      address: false,
      profile: false,
    });
    const [propertyTypes,setPropertyTypes] = useState([]);
    const [standoutAmenities,setStandoutAmenities] =useState([]);
    const [developers,setDevelopers] =useState([]);
    const [agents,setAgents] =useState([]);
    const [input,setInput] = useState([])
    const [addressInput,setAddressInput] = useState([])
    const [errors, setErrors] = useState({});
    const [addresserrors, setAddresserrors] = useState({});
    const [propertyId, setPropertyId] = useState('');
    const [logoImage,setLogoImage] = useState(null);
    const [coverImage,setCoverImage] = useState(null);
    const [brochurePdf,setBrochurePdf] = useState(null);
    const [floorPlanPdf,setFloorPlanPdf] = useState(null);
    const [mediaImages,setMediaImages] = useState([]);
    const [propertyAmenities,setPropertyAmenities] = useState([]);
    const [propertyStatus,setPropertyStatus] = useState(null);
    const [propertyBrokerage,setPropertyBrokerage] =useState([]);
    const toast = useToast()

    const getPropertyTypes = () => {
      axios.get(API_BASE_URL+'get/property/types',{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        setPropertyTypes(response.data.data)
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  
    const getStandoutAmenities = () => {
      axios.get(API_BASE_URL+'get/standout/amenity',{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        setStandoutAmenities(response.data.data)
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  
    const getDeveloper = () => {
      axios.get(API_BASE_URL+'get/developer',{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        setDevelopers(response.data.data)
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }

    const getBrokerage = () => {
      axios.get(API_BASE_URL+'get/all/brokerages',{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        setPropertyBrokerage(response.data.data)
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }

    const getAgents = () => {
      axios.get(API_BASE_URL+'get/agent',{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        setAgents(response.data.data)
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  
    const validateForm = (data) => {
      const errors = {};
      if (!data.property_title) {
        errors.property_title = 'Property title is required';
      }

      if (!data.property_agent) {
        errors.property_agent = 'Property agent is required';
      }
  
      if (!data.property_developer) {
        errors.property_developer = 'Property developer is required';
      }

      if (!data.property_brokerage) {
        errors.property_brokerage = 'Property brokerage is required';
      }
      
      if (!data.listing_type) {
        errors.listing_type = 'This field is required';
      }
  
      if (!data.property_type_ids) {
        errors.property_type_ids = 'Property type is required';
      }
  
      if (!data.standout_amenity_ids) {
        errors.standout_amenity_ids = 'Standout amenity is required';
      }
  
      if (!data.bedroom_number) {
        errors.bedroom_number = 'Bedroom number is required';
      }
  
      if (!data.bathroom_number) {
        errors.bathroom_number = 'Bathroom number is required';
      }
  
      if (!data.square_feet) {
        errors.square_feet = 'Square feet is required';
      }
  
      if (!data.square_meter) {
        errors.square_meter = 'Square meter is required';
      }
  
      if (!data.currency) {
        errors.currency = 'Currency is required';
      }
  
      if (!data.property_amount) {
        errors.property_amount = 'Property amount is required';
      }
  
      if (!data.description_title) {
        errors.description_title = 'Description title is required';
      }
  
      if (!data.description) {
        errors.description = 'Description content is required';
      }

      if (!data.website) {
        errors.website = 'Website field is required';
      }
      return errors;
    };
  
    const handleSubmitPropertyInfo = (e) => {
      e.preventDefault();
      const newErrors = validateForm(input);
      setErrors(newErrors);
  
      if (Object.keys(newErrors).length === 0) {
          const payload = {
            "property_title":input.property_title,
            "property_agent":input.property_agent,
            "property_developer":input.property_developer,
            "listing_type":input.listing_type,
            "property_type_ids":input.property_type_ids,
            "standout_amenity_ids":input.standout_amenity_ids,
            "bedroom_number":input.bedroom_number,
            "bathroom_number":input.bathroom_number,
            "square_feet":input.square_feet,
            "square_meter":input.square_meter,
            "currency":input.currency,
            "property_amount":input.property_amount,
            "propertyId":propertyId,
            "description_title":input.description_title,
            "description":input.description,
            "website":input.website,
            "property_brokerage":input.property_brokerage,
          }

          axios.post(API_BASE_URL+'property/store-update',payload,{
            headers: {
              Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
            }
          })
          .then(function (response){
            
            if(response.data.apistatus === true){
              setPropertyId(response.data.data.propertyId);
              toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'});
              locationTab.current.click();
            }else if(response.data.apistatus === false){
              toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
            }
          })
          .catch(function (error){
            toast({ title: error,status: 'error',isClosable: true,position: 'top'})
          })
      }
    };
  
    const handleAddressChange = (e) => {
      const { name, value } = e.target;
      setAddressInput({
          ...addressInput,
          [name]: value,
      });
    };
  
    const validateAddressForm = (data) => {
      const addresserrors = {};
      if (!data.property_location.trim()) {
        addresserrors.property_location = 'Address is required';
      }
  
      if (!data.property_lat.trim()) {
        addresserrors.property_lat = 'Address latitude is required';
      }
  
      if (!data.property_lng.trim()) {
        addresserrors.property_lng = 'Address longitude is required';
      }
      return addresserrors;
    };
  
    const handleSubmitPropertyAddress = (e) => {
      e.preventDefault();
      const addressErrors = validateAddressForm(addressInput);
      setAddresserrors(addressErrors);
      if (Object.keys(addressErrors).length === 0) {
        const payload = {
          "property_location":addressInput.property_location,
          "property_lat":addressInput.property_lat,
          "property_lng":addressInput.property_lng,
          "propertyId":propertyId,
        }
        axios.post(API_BASE_URL+'property/update-location',payload,{
          headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then(function (response){
          if(response.data.apistatus === true){
            toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
            mediaTab.current.click();
          }else if(response.data.apistatus === false){
            toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
          }
        })
        .catch(function (error){
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
      }
    }
  
    const handlelogoImageUpload = (acceptedFiles) => {
      const formData = new FormData();
      formData.append("logoImage",acceptedFiles[0]);
      formData.append("property_id",propertyId);
      axios.post(API_BASE_URL+'property/upload/logo-image-new',formData,{
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === true){
            setLogoImage(response.data.data);
            toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
            //locationTab.current.click()
        }else if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
    }
  
    const handleCoverImageUpload = (acceptedFiles) => {
      const formData = new FormData();
      formData.append("coverImage",acceptedFiles[0]);
      formData.append("property_id",propertyId);
      axios.post(API_BASE_URL+'property/upload/cover-image-new',formData,{
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === true){
          setCoverImage(response.data.data)
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
          //locationTab.current.click()
        }else if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
    }
  
    const handleMediaImageUpload = (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const formData = new FormData();
        formData.append("mediaImage",file);
        formData.append("property_id",propertyId);
        axios.post(API_BASE_URL+'property/upload/media-images-new',formData,{
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then(function (response){
          if(response.data.apistatus === true){
            setMediaImages(response.data.data);
            toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
            //locationTab.current.click()
          }else if(response.data.apistatus === false){
            toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
          }
        })
        .catch((error) => {
            toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
      });
    }

    const handleBrochurePdfUpload = (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
        const formData = new FormData();
        formData.append("brochurePdf",file);
        formData.append("property_id",propertyId);
        axios.post(API_BASE_URL+'property/upload/brochure',formData,{
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
        })
        .then(function (response){
          if(response.data.apistatus === true){
            setBrochurePdf(response.data.data);
            toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
            //locationTab.current.click()
          }else if(response.data.apistatus === false){
            toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
          }
        })
        .catch((error) => {
            toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
      });
    }

    const handleFloorPlanPdfUpload = (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
      const formData = new FormData();
      formData.append("floorPlanPdf",file);
      formData.append("property_id",propertyId);
      axios.post(API_BASE_URL+'property/upload/floor-plan',formData,{
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === true){
          setFloorPlanPdf(response.data.data);
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
          //locationTab.current.click()
        }else if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
      .catch((error) => {
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    });
  }
  
    const handleSubmitPropertyMedia = (e) => {

      if (mediaImages.length === 0) {
        toast({ title: 'Please select media images.',status: 'error',isClosable: true,position: 'top'})
        return false;
      }

      const payload = {
        'mediaImages': mediaImages,
        'propertyId': propertyId,
      };
      axios.post(API_BASE_URL+'media/update-priority',payload,{
          headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          }
      })
      .then(function (response){
        toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
        navigate("/admin/properties/overview");
      }) 
      .catch((error) => {
          toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }

    const getProperty = (e) => {
        axios.get(API_BASE_URL+'get/property/'+id,{
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
            }
        })
        .then(function (response){
            
            setPropertyId(response.data.data.property.id);
            setInput({
                property_title: response.data.data.property.title,
                property_agent: response.data.data.property.user_id,
                property_developer: response.data.data.property.developer_id,
                listing_type: response.data.data.property.listing_type_id,
                property_type_ids: response.data.data.property.property_type_id,
                standout_amenity_ids: response.data.data.propertyAmenitiesArray,
                bedroom_number: response.data.data.property.number_of_bedroom,
                bathroom_number: response.data.data.property.number_of_bathroom,
                square_feet: response.data.data.property.square_feet,
                square_meter: response.data.data.property.square_meter,
                currency: response.data.data.property.currency,
                property_amount: response.data.data.property.amount,
                description_title: response.data.data.property.description_title,
                description: response.data.data.property.description_string,
                website: response.data.data.property.website,
                property_brokerage: response.data.data.property.brokerage_id,
            });
            setPropertyStatus(response.data.data.property.status);
            setPropertyAmenities(response.data.data.propertyAmenities); 
            setAddressInput({
                property_location: response.data.data.property.location_title,
                property_lat: response.data.data.property.location_lat,
                property_lng: response.data.data.property.location_long
            });
            setLogoImage(response.data.data.property.logo);
            setCoverImage(response.data.data.property.cover_photo);
            setMediaImages(response.data.data.property.mediaImages); 
            setBrochurePdf(response.data.data.property.brochure);
            setFloorPlanPdf(response.data.data.property.floor_plan);
        })
        .catch((error) => {
            toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
    }

    const handleChange = (e) => {
      const { name, value } = e.target;
      setInput({
          ...input,
          [name]: value,
      });
    };

    const handleAmenitySelectValue = (value) => {
      input.standout_amenity_ids = [];
      value.forEach(function(v) {
        input.standout_amenity_ids.push(v.id);
      });
    };

    const removeImage = (type,image) => {
        const payload = {
            'property_id': id,
            'type': type,
            'image': image
        }
        axios.post(API_BASE_URL+'delete/property-image-new',payload,{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
            }
        })
        .then(function (response){
            if(type === 1){
                setLogoImage(null)
            }
            if(type === 2){
                setCoverImage(null)
            }
            if(type === 3){
                setMediaImages(response.data.data)
            }
            if(type === 4){
              setBrochurePdf(null)
            }
            if(type === 5){
              setFloorPlanPdf(null)
            }
        })
        .catch((error) => {
            toast({ title: error,status: 'error',isClosable: true,position: 'top'})
        })
    };

    const onSortEnd = (oldIndex: number, newIndex: number) => {
      setMediaImages((array) => arrayMove(array, oldIndex, newIndex));
    };

    const classes = useStyles();
  
    useEffect(() => {
      getPropertyTypes();
      getStandoutAmenities();
      getDeveloper();
      getBrokerage();
      getAgents();
      getProperty();
    },[]);
  
    return (
  
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: '125px', lg: '75px' }}
        position="relative"
      >
        <Box
          h="45vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        />
  
        <Tabs
          variant="unstyled"
          zIndex="0"
          mt={{ base: '60px', md: '165px' }}
          display="flex"
          flexDirection="column"
        >
          <TabList
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
          >
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={propertyTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  address: false,
                  profile: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.address ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '30px' },
                  top: {
                    sm: activeBullets.user ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  bottom: activeBullets.user ? '40px' : '38px',
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.user ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.user ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.user ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Property Info
                </Text>
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={locationTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  address: true,
                  profile: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.profile ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '32px' },
                  top: '6px',
                  position: 'absolute',
                  bottom: activeBullets.address ? '40px' : '38px',
                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.address ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.address ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.address ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Location
                </Text>
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={mediaTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  user: true,
                  address: true,
                  profile: true,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.profile ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.profile ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.profile ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Media
                </Text>
              </Flex>
            </Tab>
          </TabList>
          <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Property Info
                </Text>
                <form onSubmit={handleSubmitPropertyInfo}>
                  <Flex direction="column" w="100%">
  
                    <Stack direction="column" spacing="20px" margin="20px 0px">
                      <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                        <Flex direction="column">
                          <InputField
                            id="property_title"
                            placeholder="Description Title"
                            label="Property Title"
                            name="property_title"
                            mb="0px"
                            onChange={handleChange}
                            value={input.property_title}
                          />
                          {errors.property_title &&
                            <span className="error-message">
                                {errors.property_title}
                            </span>
                          }
                        </Flex>

                        <Flex direction="column">
                            <FormLabel
                              ms="10px"
                              htmlFor="developer"
                              fontSize="sm"
                              color=''
                              fontWeight="bold"
                              _hover={{ cursor: 'pointer' }}
                            >
                              Agent
                            </FormLabel>
    
                            <Select
                              id="agent"
                              value={input.property_agent}
                              name="property_agent"
                              onChange={handleChange}
                              borderRadius="16px"
                            >
                              <option value=''>Select...</option>
                              {agents.map((option) => (
                                <option value={option.value}>{option.name}-{option.user_name}</option >
                              ))}
                            </Select>
                            {errors.property_agent &&
                              <span className="error-message">
                                  {errors.property_agent}
                              </span>
                            }
                        </Flex>
                      </SimpleGrid>
                    </Stack>
  
                    <Stack direction="column" spacing="20px">
                      <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                        <Flex direction="column">
                          <FormLabel
                            ms="10px"
                            htmlFor="developer"
                            fontSize="sm"
                            color=''
                            fontWeight="bold"
                            _hover={{ cursor: 'pointer' }}
                          >
                            Developer
                          </FormLabel>
  
                          <Select
                            id="developer"
                            value={input.property_developer}
                            name="property_developer"
                            onChange={handleChange}
                            borderRadius="16px"
                          >
                            <option value=''>Select...</option>
                            {developers.map((option) => (
                              <option value={option.value}>{option.label}</option >
                            ))}
                          </Select>
                          {errors.property_developer &&
                            <span className="error-message">
                                {errors.property_developer}
                            </span>
                          }
                        </Flex>

                        <Flex direction="column">
                        <FormLabel
                          ms="10px"
                          htmlFor="brokerage"
                          fontSize="sm"
                          color=''
                          fontWeight="bold"
                          _hover={{ cursor: 'pointer' }}
                        >
                          Brokerage
                        </FormLabel>

                        <Select
                          id="brokerage"
                          value={input.property_brokerage}
                          name="property_brokerage"
                          onChange={handleChange}
                          borderRadius="16px"
                        >
                          <option value=''>Select...</option>
                          {propertyBrokerage.map((option) => (
                            <option value={option.value}>{option.label}</option >
                          ))}
                        </Select>
                        {errors.property_brokerage &&
                          <span className="error-message">
                              {errors.property_brokerage}
                          </span>
                        }
                      </Flex>
  
                        <Flex direction="column">
                          <FormLabel
                            ms="10px"
                            htmlFor="listing-type"
                            fontSize="sm"
                            color=''
                            fontWeight="bold"
                            _hover={{ cursor: 'pointer' }}
                          >
                            For
                          </FormLabel>
  
                          <Select
                            id="listing-type"
                            value={input.listing_type}
                            name="listing_type"
                            onChange={handleChange}
                            borderRadius="16px"
                          >
                            <option value=''>Select...</option>
                            <option value="1">Sale</option >
                            <option value="2">Rent</option >
                          </Select>
                          {errors.listing_type &&
                            <span className="error-message">
                                {errors.listing_type}
                            </span>
                          }
                        </Flex>
  
                        <Flex direction="column">
                          <FormLabel
                            ms="10px"
                            htmlFor="property-type"
                            fontSize="sm"
                            color=''
                            fontWeight="bold"
                            _hover={{ cursor: 'pointer' }}
                          >
                            Which of these best describes the property?
                          </FormLabel>
  
                          <Select
                            id="property-type"
                            value={input.property_type_ids}
                            name="property_type_ids"
                            onChange={handleChange}
                            borderRadius="16px"
                          >
                            <option value=''>Select...</option>
                            {propertyTypes.map((option) => (
                              <option value={option.value}>{option.label}</option >
                            ))}
                          </Select>
                          {errors.property_type_ids &&
                            <span className="error-message">
                                {errors.property_type_ids}
                            </span>
                          }
                        </Flex>
  
                        <Flex direction="column">
                          <FormLabel
                            ms="10px"
                            htmlFor="standout-amenity"
                            fontSize="sm"
                            color=''
                            fontWeight="bold"
                            _hover={{ cursor: 'pointer' }}
                          >
                            Do you have any standout amenities?
                          </FormLabel>

                          <Multiselect
                            options={standoutAmenities} // Options to display in the dropdown
                            selectedValues={propertyAmenities} // Preselected value to persist in dropdown
                            onSelect={handleAmenitySelectValue} // Function will trigger on select event
                            onRemove={handleAmenitySelectValue} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                            borderRadius="16px"
                          />
  
                          {errors.standout_amenity_ids &&
                            <span className="error-message">
                                {errors.standout_amenity_ids}
                            </span>
                          }
                        </Flex>
  
                        <Flex direction="column">
                          <InputField
                            mb="0px"
                            id="bedroom-number"
                            placeholder="eg. 2"
                            label="No. of Bedrooms"
                            name="bedroom_number"
                            onChange={handleChange}
                            value={input.bedroom_number}
                          />
                          {errors.bedroom_number &&
                            <span className="error-message">
                                {errors.bedroom_number}
                            </span>
                          }
                        </Flex>
                        
                        <Flex direction="column">
                          <InputField
                            mb="0px"
                            id="bathroom-number"
                            placeholder="eg. 2"
                            label="No. of Bathrooms"
                            name="bathroom_number"
                            onChange={handleChange}
                            value={input.bathroom_number}
                          />
                          {errors.bathroom_number &&
                            <span className="error-message">
                                {errors.bathroom_number}
                            </span>
                          }
                        </Flex>
  
                        <Flex direction="column">
                          <InputField
                            mb="0px"
                            id="square-feet"
                            placeholder="3500"
                            label="Square feet"
                            name="square_feet"
                            onChange={handleChange}
                            value={input.square_feet}
                          />
                          {errors.square_feet &&
                            <span className="error-message">
                                {errors.square_feet}
                            </span>
                          }
                        </Flex>
  
                        <Flex direction="column">
                          <InputField
                            mb="0px"
                            id="square-meter"
                            placeholder="325"
                            label="Square meter"
                            name="square_meter"
                            onChange={handleChange}
                            value={input.square_meter}
                          />
                          {errors.square_meter &&
                            <span className="error-message">
                                {errors.square_meter}
                            </span>
                          }
                        </Flex>
  
                        <Flex direction="column">
                          <FormLabel
                            ms="10px"
                            htmlFor="property-currency"
                            fontSize="sm"
                            color=''
                            fontWeight="bold"
                            _hover={{ cursor: 'pointer' }}
                          >
                            Now, set your price
                          </FormLabel>
  
                          <Select
                            id="standout-amenity"
                            value={input.currency}
                            name="currency"
                            onChange={handleChange}
                            borderRadius="16px"
                          >
                            <option value=''>Select...</option>
                            <option value="AED">AED</option>
                            <option value="USD">USD</option>
                            <option value="GBP">GBP</option>
                            <option value="EURO">EURO</option>
                          </Select>
                          {errors.currency &&
                            <span className="error-message">
                                {errors.currency}
                            </span>
                          }
                        </Flex>
  
                        <Flex direction="column">
                          <InputField
                            mb="0px"
                            id="property-amount"
                            placeholder="Property Amount"
                            label="Property amount"
                            name="property_amount"
                            onChange={handleChange}
                            value={input.property_amount}
                          />
                          {errors.property_amount &&
                            <span className="error-message">
                                {errors.property_amount}
                            </span>
                          }
                        </Flex>
                      </SimpleGrid>
                    </Stack>

                    <Stack direction="column" spacing="20px" margin="20px 0px">
                      <Flex direction="column">
                        <InputField
                          id="website"
                          placeholder="Website"
                          label="Website"
                          name="website"
                          mb="0px"
                          onChange={handleChange}
                          value={input.website}
                        />
                        {errors.website &&
                          <span className="error-message">
                              {errors.website}
                          </span>
                        }
                      </Flex>
                    </Stack>
  
                    <Stack direction="column" spacing="20px" margin="20px 0px">
                      <Flex direction="column">
                        <InputField
                          id="description_title"
                          placeholder="Description Title"
                          label="Description Title"
                          name="description_title"
                          mb="0px"
                          onChange={handleChange}
                          value={input.description_title}
                        />
                        {errors.description_title &&
                          <span className="error-message">
                              {errors.description_title}
                          </span>
                        }
                      </Flex>
  
                      <Flex direction="column">
                        <TextField
                          minH="500px"
                          id="description"
                          placeholder="Enter a few words about property"
                          label="Description Content"
                          name="description"
                          onChange={handleChange}
                          style={{whiteSpace: "pre-line"}}
                          value={input.description}
                        />
                        {errors.description &&
                          <span className="error-message">
                              {errors.description}
                          </span>
                        }
                      </Flex>
                    </Stack>
  
                    <Flex justify="space-between" mt="24px">
                      <Button
                        variant="darkBrand"
                        fontSize="sm"
                        borderRadius="16px"
                        w={{ base: '128px', md: '148px' }}
                        h="46px"
                        ms="auto"
                        type='submit'
                        // onClick={() => locationTab.current.click()}
                      >
                        Next
                      </Button>
                    </Flex>
                  </Flex>
                </form>
              </Card>
            </TabPanel>
  
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Address
                </Text>
                <form onSubmit={handleSubmitPropertyAddress}>
                  <Flex direction="column" w="100%">
                    <Stack direction="column" spacing="20px" mb="20px">
                      <InputField
                        mb="0px"
                        id="property_location"
                        placeholder="eg. India"
                        label="Please enter your Property address into the search bar of the Map."
                        name="property_location"
                        onChange={handleAddressChange}
                        value={addressInput.property_location}
                      />
                      {addresserrors.property_location &&
                        <span className="error-message">
                            {addresserrors.property_location}
                        </span>
                      }
  
                      <InputField
                        mb="0px"
                        id="property_lat"
                        placeholder="eg. 123.5"
                        label="Please enter your Property address latitude."
                        name="property_lat"
                        onChange={handleAddressChange}
                        value={addressInput.property_lat}
                      />
                      {addresserrors.property_lat &&
                        <span className="error-message">
                            {addresserrors.property_lat}
                        </span>
                      }
  
                      <InputField
                        mb="0px"
                        id="property_lng"
                        placeholder="eg. 456.2"
                        label="Please enter your Property address longitude."
                        name="property_lng"
                        onChange={handleAddressChange}
                        value={addressInput.property_lng}
                      />
                      {addresserrors.property_lng &&
                        <span className="error-message">
                            {addresserrors.property_lng}
                        </span>
                      }
  
                    </Stack>
                    <Flex justify="space-between">
                      <Button
                        variant="light"
                        fontSize="sm"
                        borderRadius="16px"
                        w={{ base: '128px', md: '148px' }}
                        h="46px"
                        onClick={() => propertyTab.current.click()}
                      >
                        Prev
                      </Button>
                      <Button
                        variant="darkBrand"
                        fontSize="sm"
                        borderRadius="16px"
                        w={{ base: '128px', md: '148px' }}
                        h="46px"
                        ms="auto"
                        type='submit'
                        //onClick={() => mediaTab.current.click()}
                      >
                        Next
                      </Button>
                    </Flex>
                  </Flex>
                </form>
              </Card>
            </TabPanel>
  
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Media
                </Text>
                  <Flex direction="column" w="100%">
                    <FormLabel
                      ms="10px"
                      htmlFor="property_logo"
                      fontSize="sm"
                      color=''
                      fontWeight="bold"
                      _hover={{ cursor: 'pointer' }}
                    >
                      Logo
                    </FormLabel>
  
                    <Dropzone onDrop={handlelogoImageUpload} accept="image/*" maxFiles={1}>
                      {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                        const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";
  
                        return (
                          <div
                          {...getRootProps({
                            className: `dropzone ${additionalClass}`
                          })}
                          >
                            <input {...getInputProps()} />
                            <p>Drag'n'drop images,or click to select files</p>
                          </div>
                        );
                      }}
                    </Dropzone>
                    {logoImage && (
                      <div className='new_box'>
                        <img src={ASSETS_URL+'properties/logo/original/'+logoImage} className='img-container logo-image' alt='upload cover file'/>
                        <button className='remove-image' onClick={() => removeImage(1,logoImage)}>X</button>
                      </div>
                    )}
                    
                    {/* <FormLabel
                      ms="10px"
                      htmlFor="cover_image"
                      fontSize="sm"
                      color=''
                      fontWeight="bold"
                      _hover={{ cursor: 'pointer' }}
                    >
                      Cover Image
                    </FormLabel>
  
                    <Dropzone onDrop={handleCoverImageUpload} accept="image/*" maxFiles={1}>
                      {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                        const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";
  
                        return (
                          <div
                          {...getRootProps({
                            className: `dropzone ${additionalClass}`
                          })}
                          >
                            <input {...getInputProps()} />
                            <p>Drag'n'drop images,or click to select files</p>
                          </div>
                        );
                      }}
                    </Dropzone>
                    {coverImage && (
                      <div class="new_box">
                        <img src={ASSETS_URL+'properties/media/original/'+coverImage} className='img-container cover-image' alt='upload cover file' width="50%"/>
                        <button className='remove-image' onClick={() => removeImage(2,coverImage)}>X</button>
                      </div>
                    )} */}
  
                    <FormLabel
                      ms="10px"
                      htmlFor="media_images"
                      fontSize="sm"
                      color=''
                      fontWeight="bold"
                      _hover={{ cursor: 'pointer' }}
                    >
                      Media Images
                    </FormLabel>
  
                    <Dropzone onDrop={handleMediaImageUpload} accept="image/*">
                      {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                        const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";  
                        return (
                          <div
                          {...getRootProps({
                            className: `dropzone ${additionalClass}`
                          })}
                          >
                            <input {...getInputProps()} />
                            <p>Drag'n'drop images,or click to select files</p>
                          </div>
                        );
                      }}
                    </Dropzone>
                    
                    {mediaImages.length > 0 &&
                      <SortableList onSortEnd={onSortEnd} className={classes.root} draggedItemClassName={classes.dragged}>
                      {mediaImages.map((item,index) => (
                        <SortableItem key={ASSETS_URL+'properties/media/small/'+item}>
                          <div className={classes.item}>
                            <img src={ASSETS_URL+'properties/media/small/'+item} className={classes.image} alt='media' />
                            <button className='remove-image' onClick={() => removeImage(3,item)}>X</button>
                          </div>
                        </SortableItem>
                      ))}
                      </SortableList>
                    }

                    <FormLabel
                      ms="10px"
                      htmlFor="property_logo"
                      fontSize="sm"
                      color=''
                      fontWeight="bold"
                      _hover={{ cursor: 'pointer' }}
                    >
                      Brochure
                    </FormLabel>
                    
                    {brochurePdf && (
                      <div className='pdf_box'>
                        <a href = {ASSETS_URL+'properties/brochure/'+brochurePdf} rel='noreferrer' target = "_blank">Download PDF</a>
                        <button className='remove-pdf' onClick={() => removeImage(4,brochurePdf)}><IoMdClose /></button>
                      </div>
                    )}

                    <Dropzone onDrop={handleBrochurePdfUpload} accept=".pdf" maxFiles={1}>
                      {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                        const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";
  
                        return (
                          <div
                          {...getRootProps({
                            className: `dropzone ${additionalClass}`
                          })}
                          >
                            <input {...getInputProps()} />
                            <p>Drag'n'drop images,or click to select files</p>
                          </div>
                        );
                      }}
                    </Dropzone>
                    

                    <FormLabel
                      ms="10px"
                      htmlFor="property_logo"
                      fontSize="sm"
                      color=''
                      fontWeight="bold"
                      _hover={{ cursor: 'pointer' }}
                    >
                      Floor plan
                    </FormLabel>

                    {floorPlanPdf && (
                      <div className='pdf_box'>
                        <a href = {ASSETS_URL+'properties/floorplan/'+floorPlanPdf} rel='noreferrer' target = "_blank">Download PDF</a>
                        <button className='remove-pdf' onClick={() => removeImage(5,floorPlanPdf)}><IoMdClose /></button>
                      </div>
                    )}
  
                    <Dropzone onDrop={handleFloorPlanPdfUpload} accept=".pdf" maxFiles={1}>
                      {({getRootProps, getInputProps, isDragActive, isDragAccept,isDragReject }) => {
                        const additionalClass = isDragAccept ? "accept" :isDragReject ? "reject" : "";
  
                        return (
                          <div
                          {...getRootProps({
                            className: `dropzone ${additionalClass}`
                          })}
                          >
                            <input {...getInputProps()} />
                            <p>Drag'n'drop images,or click to select files</p>
                          </div>
                        );
                      }}
                    </Dropzone>
                    
  
                    <Flex justify="" mt="24px">
                      <Button
                        variant="light"
                        fontSize="sm"
                        borderRadius="16px"
                        w={{ base: '128px', md: '148px' }}
                        h="46px"
                        onClick={() => locationTab.current.click()}
                      >
                        Prev
                      </Button>
                      {propertyStatus == 2 && (
                        <span className='note'><b>Note</b> :- After hit 'submit button', Property will live on app and notify to user.</span>
                      )}
                      
                    </Flex>

                    <Flex justify="center" mt="24px">
                      <Button
                          variant="darkBrand"
                          fontSize="sm"
                          borderRadius="16px"
                          w={{ base: '128px', md: '148px' }}
                          h="46px"
                          type='submit'
                          onClick={handleSubmitPropertyMedia}
                        >
                          Submit
                        </Button>
                    </Flex>
                    
                </Flex>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    );
    
  }
  