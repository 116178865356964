export const columnsDataBrokersOverview = [
    {
      Header: "ACTIONS",
      accessor: "broker_id",
    }, 
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Broker Number",
      accessor: "broker_number",
    },
    {
      Header: "Profile Image",
      accessor: "profile_picture",
    },
    {
      Header: "NAME", 
      accessor: "name",
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "PHONE NUMBER",
      accessor: "phone_number",
    },
    {
      Header: "Account Type",
      accessor: "account_type",
    },  
    {
      Header: "Created At",
      accessor: "date",
    }
  ];
  