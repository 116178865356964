export const columnsDataBusinessOverview = [
    {
      Header: "ACTIONS",
      accessor: "business_id",
    },
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Business Image",
      accessor: "image",
    },
    {
      Header: "Title", 
      accessor: "title",
    },
    {
      Header: "Created At",
      accessor: "date",
    }
  ];
  