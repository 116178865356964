import { Flex,useToast } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useEffect, useState } from "react";
import SearchTableStories from "views/admin/story/overview/components/SearchTableStoriesOverivew";
import { columnsDataStoriesOverview } from "views/admin/story/overview/variables/columnsDataStoriesOverview";
import axios from "axios";
import { API_BASE_URL } from "constants/apiConstants";

export default function StoriesOverview() {
  const [stories,setStories] = useState([]);
  const toast = useToast()
  const fetchProperties = () => {
    axios.get(API_BASE_URL+'get/stories',{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      }
    })
    .then((response) => {
        setStories(response.data.data)
    })
    .catch((error) => {
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  useEffect(() => {
    fetchProperties();
  },[]);

  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='0px'>
        <SearchTableStories
          tableData={stories}
          columnsData={columnsDataStoriesOverview}
        />
      </Card>
    </Flex>
  );
}