export const columnsDataStoriesOverview = [
    {
        Header: "ACTIONS",
        accessor: "id",
    },
    {
        Header: "Id",
        accessor: "story_id",
    },
    {
        Header: "User",
        accessor: "user.name",
    },
    {
        Header: "Image",
        accessor: "image",
    },
    {
        Header: "Created At",
        accessor: "date",
    },
    
];
    