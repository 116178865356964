import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  Flex,
  useToast, 
  Icon,
  Badge,
  Text,
  useColorModeValue,
} 
from "@chakra-ui/react";
import Card from "components/card/Card";
import { API_BASE_URL } from "constants/apiConstants";
import axios from 'axios';
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import 'primeicons/primeicons.css';

export default function PropertyTypesOverview() {
  const [propertyTypes,setPropertyTypes] = useState([]);
  const toast = useToast();
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const [rowCount, setRowCount] = useState(0);
  const [isTrue, setIsTrue] = useState(true);

  const fetchPropertyTypes = () => {
    const payload = {
      'rowCount' : rowCount
    };
    axios.post(API_BASE_URL+'get/all/property/type/list',payload,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      }
    })
    .then((response) => {
      setPropertyTypes(response.data.data.propertyTypeList);
      setRowCount(response.data.data.rowCount);
    })
    .catch((error) => {
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  }

  const columns = [
      { field: 'id', header: 'actions' },
      { field: 'value', header: 'Id' },
      { field: 'label', header: 'Title' },
      { field: 'status', header: 'Status' },
      { field: 'date', header: 'Created At' },
  ];

  const handleRowOrderChange = async (params) => {
    const payload ={
      'dropIndex' : params.dropIndex,
      'value' : params.value
    }

    axios.post(API_BASE_URL+'update/property-type/priority',payload,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      }
    })
    .then((response) => {
      setPropertyTypes(params.value)
    })
    .catch((error) => {
      toast({ title: error,status: 'error',isClosable: true,position: 'top'})
    })
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Flex cursor='pointer' mx="auto">
        <Link to={`/admin/property-types/edit-property-type/${rowData.id}`}>
          <Icon color='secondaryGray.500' as={MdEdit} w='20px' h='20px' />
        </Link>
      </Flex>
    );
  };

  const statusBodyTemplate = (rowData) => {
    if(rowData.status === 1){
      var statusValue = <Badge colorScheme='green'>Active</Badge>;
    }else if(rowData.status === 0){
      var statusValue = <Badge colorScheme='red'>In Active</Badge>;
    }
    return (
      <Flex align='center'>
        <Text
          color={textColor}
          fontSize='md'
          fontWeight='500'>
            {statusValue}
        </Text>
      </Flex>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
        <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
            <div className='d-flex'>
              <div className='search-box dev-search-box'>
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </IconField>
              </div>
            </div>
        </div>
    );
  };

  const scrollEvent = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && isTrue) { 
      const payload = {
        'rowCount' : rowCount
      };
      axios.post(API_BASE_URL+'get/all/property/type/list',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        if(response.data.data.propertyTypeList.length > 0){
          setPropertyTypes(propertyTypes => [...propertyTypes,...response.data.data.propertyTypeList]);
          setRowCount(response.data.data.rowCount);
        }else{
          setIsTrue(false);
        }
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  }

  const header = renderHeader();

  const dynamicColumns = columns.map((col, i) => {
    if(col.header === 'actions'){
      return <Column header={col.header} body={actionBodyTemplate} exportable={false}></Column>;
    }if(col.header === 'Status'){
      return <Column header={col.header} body={statusBodyTemplate} exportable={false}></Column>;
    }else{
      return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} />;
    }
  });

  useEffect(() => {
    fetchPropertyTypes();
  }, []);

  return (
    <>
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='0px'>
          <div className="card">
            <DataTable value={propertyTypes} header={header}
                    reorderableColumns reorderableRows onRowReorder={handleRowOrderChange}
                    filters={filters} globalFilterFields={['id', 'label', 'date']}
                    onScroll={scrollEvent}
                    style={{ overflowY: 'scroll', height: '800px'}}
                    emptyMessage="No property type found.">
                  <Column rowReorder style={{ width: '3rem' }} />
                  {dynamicColumns}
            </DataTable>
          </div>
        </Card>
    </Flex>
  </>
  );
}
        